import NextImage from '../../NextImage';
import CategorySubCategoryText from './CategorySubCategoryText';
import InfoTmpl from './InfoTmpl';
import { Text as Paragraph, Title } from '../../Typography';
import HiddenContent from './HiddenContent';
import { useRef } from 'react';
import { cardPropsByType } from '../index';
import { CardType, CardVariation, ICardItem, ILink } from '../types';
import VideoTmpl from './VideoTmpl';
import clsx from 'clsx';
import CurveSvg from './CurveSvg';
import ContentWrapper from './ContentWrapper';
import { getCardBgColor } from '../helpers/getCardBgColor';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';

interface IRegularCardContentProps {
  cardType?: CardType;
  isAuthorableGrid?: boolean;
  cardColor?: boolean;
  cardVariation?: CardVariation;
  isPlaceholder: boolean;
  video?: boolean | string;
  data?: ICardItem;
  textAlign?: string;
  copyDictionary?: Record<string, string>;
  showInfo?: boolean;
  showAll?: boolean;
  link?: ILink;
  cardImage: string;
  isOdd?: boolean;
}

const RegularCardContent = ({
  cardType,
  isAuthorableGrid,
  cardColor,
  cardVariation,
  isPlaceholder,
  video,
  data,
  textAlign,
  copyDictionary,
  showInfo,
  showAll,
  link,
  cardImage,
  isOdd,
}: IRegularCardContentProps) => {
  const { theme } = useGlobalContext();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const backgroundHsl =
    !theme && cardColor
      ? '#F4F5F4'
      : getCardBgColor({
          theme,
          cardVariation,
          isOdd,
          customColor: cardColor ? '#F4F5F4' : undefined,
        });

  const getTitleType = () => {
    // make font size responsive to card width
    if (contentRef.current) {
      const elmRect = contentRef.current?.getBoundingClientRect();
      if (elmRect?.width < 370 && isAuthorableGrid) {
        return 'xxxs';
      }
    }

    return cardPropsByType[cardType || 'default'].titleType;
  };

  const titleType = getTitleType();
  const getObjectImage = () => {
    if (data?.button) {
      return 'w-6/12';
    }

    if (cardType === 'mediumSmall' && cardVariation === 'product') {
      return 'w-auto max-h-productCardHeight';
    }

    return 'w-8/12';
  };

  const objectImage = getObjectImage();

  return (
    <ContentWrapper
      ref={contentRef}
      backgroundHsl={backgroundHsl}
      variation={cardVariation}
      type={cardType}
      isPlaceholder={isPlaceholder}
    >
      {video && <VideoTmpl cardType={cardType} cardColor={backgroundHsl} />}
      {cardVariation === 'object' && (
        <div className="transition-transform duration-[400ms] card-image z-[9] w-11/12 flex justify-center items-center bottom-[186px] h-[180px] absolute group-hover:transform group-hover:scale-75">
          <NextImage
            className={clsx(objectImage, 'max-h-full object-contain')}
            mimeType={String(data?.images?.mimeType) || String(data?.image?.mimeType)}
            src={cardImage}
            alt="Object image"
            width={230}
            height={230}
            preferredWidth={800}
          />
        </div>
      )}
      <div className="relative card-content-big">
        <CurveSvg
          cardVariation={cardVariation}
          cardColor={cardColor}
          isOdd={isOdd}
          backgroundHsl={backgroundHsl}
        />
        <div className={cardType === 'big' ? 'relative -top-8' : ''}>
          {['article', 'articleImage', 'object', 'subcategory'].includes(cardVariation || '') ? (
            <CategorySubCategoryText
              textAlign={textAlign}
              category={data?.category}
              subCategory={data?.subCategory}
            />
          ) : (
            isPlaceholder || (
              <div
                className={
                  showAll
                    ? ''
                    : 'mmmd:max-h-0 mmmd:text-inherit mmmd:opacity-0 mmmd:pb-0 mmmd:transition-max-h-200 mmmd:transition-opacity group-hover:max-h-[100px] group-hover:opacity-100 transition-max-h transition-opacity duration-[800ms]'
                }
              >
                <CategorySubCategoryText
                  textAlign={textAlign}
                  category={data?.category}
                  subCategory={data?.subCategory}
                />
              </div>
            )
          )}

          {data?.productsAmount && (
            <Paragraph
              tag="p"
              type="sm"
              className="  uppercase text-center opacity-70 text-darkgrey tracking-widest mb-2.5 md:mb-18 md:leading-4.5"
            >
              {data.productsAmount}
            </Paragraph>
          )}
          {showInfo && data?.information && (
            <InfoTmpl
              cardVariation={cardVariation}
              copyDictionary={copyDictionary}
              information={data?.information}
            />
          )}

          {(data?.title || data?.cardTitle) && (
            <Title
              tag={isPlaceholder ? 'div' : 'h2'}
              type={titleType}
              className="text-center tracking-xs font-medium"
            >
              {data?.cardTitle ? data?.cardTitle : data?.title || ''}
            </Title>
          )}
          {isPlaceholder || (
            <HiddenContent showAll={!!showAll} textAlign={textAlign} link={link} data={data} />
          )}
        </div>
      </div>
    </ContentWrapper>
  );
};

export default RegularCardContent;
