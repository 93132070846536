import { CardVariation } from '../types';
import { clsx } from 'clsx';
import { useGlobalContext } from '@/components/Context/hooks/useGlobalContext';

const CurveSvg = ({
  cardVariation,
  cardColor,
  isOdd,
  backgroundHsl,
}: {
  cardVariation?: CardVariation;
  cardColor?: boolean;
  isOdd?: boolean;
  backgroundHsl?: string;
}) => {
  const { theme } = useGlobalContext();

  let bottomStyle = '';

  switch (cardVariation) {
    case 'product':
      bottomStyle = 'bottom-[calc(100%+72px)]';
      break;
    case 'object':
      bottomStyle = 'bottom-[calc(100%+73px)]';
      break;
    case 'recipe':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'white':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'WideSmall':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'content':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'article':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'articleImage':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
    case 'subcategory':
      bottomStyle = 'bottom-[calc(100%+36px)]';
      break;
    case 'tipsAndTricks':
      bottomStyle = 'bottom-[calc(100%+12px)]';
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 624 28"
      className={clsx(
        'curve-svg',
        cardVariation === 'product' &&
          `group-hover:[&>path]:text-[color:var(--color-accent)] group-hover:[&>path]:border-0 ${
            cardColor
              ? 'group-hover:[&>path]:fill-gray'
              : isOdd
                ? 'group-hover:[&>path]:fill-secondary'
                : 'group-hover:[&>path]:fill-cake'
          }`,
        {
          '[&>path]:fill-none ': cardVariation == 'product' && !theme,
          '[&>path]:fill-white': cardVariation === 'white',
          '[&>path]:fill-gray':
            cardColor && cardVariation !== 'product' && cardVariation !== 'white',
          '[&>path]:fill-cake':
            !cardColor && cardVariation !== 'product' && cardVariation !== 'white' && !isOdd,
          '[&>path]:fill-secondary':
            !cardColor && cardVariation !== 'product' && cardVariation !== 'white' && isOdd,
          '[&>path]:fill-emphasize': cardVariation === 'product' && theme,
        },
        cardVariation === 'product'
          ? '-left-[19px] w-[calc(100%+38px)]'
          : '-left-[50px] w-[calc(100%+100px)]',
        'absolute',
        bottomStyle,
      )}
    >
      <path
        style={{
          fill:
            cardVariation === 'white'
              ? `var(--color-emphasize, #fff)`
              : cardVariation === 'articleImage'
                ? `var(--color-secondary, ${backgroundHsl})`
                : cardVariation === 'article'
                  ? 'var(--color-important, #fff)'
                  : backgroundHsl,
        }}
        stroke={
          cardVariation === 'product'
            ? 'var(--color-accent, #BBC4BA)'
            : cardVariation === 'white'
              ? '#BBC4BA'
              : 'none'
        }
        d="M16 203C7.16344 203 0 195.837 0 187V21.351C0 21.351 143.433 -1.4836e-05 313.3 0C483.167 1.48361e-05 624 21.3511 624 21.3511V187C624 195.837 616.836 203 608 203H16Z"
      />
    </svg>
  );
};

export default CurveSvg;
