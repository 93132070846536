import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

interface IContentWrapperProps {
  backgroundHsl: string;
  variation?: string;
  type?: string;
  isPlaceholder?: boolean;
  children: ReactNode;
}

export type Ref = HTMLDivElement;

const ContentWrapper = forwardRef<Ref, IContentWrapperProps>(
  ({ backgroundHsl, variation, type, isPlaceholder, children }, ref) => {
    let variationStyle = '';

    switch (variation) {
      case 'recipe':
        variationStyle = isPlaceholder
          ? 'pt-0 hidden border-0 relative'
          : 'pt-0 absolute bottom-0 border-0';
        break;
      case 'articleImage':
        variationStyle = isPlaceholder
          ? 'pt-0 hidden border-0 relative'
          : 'pt-0 absolute bottom-0 border-0';
        break;
      case 'tipsAndTricks':
        variationStyle = isPlaceholder
          ? 'pt-0 hidden border-0 relative'
          : 'pt-0 absolute bottom-0 border-0';
        break;
      case 'white':
        variationStyle = 'pt-0 border-x border-b border-x-[#BBC4BA] border-b-[#BBC4BA] relative';
        break;
      case 'object':
        variationStyle = 'pt-[60px] border-0 absolute bottom-0';
        break;
      case 'subcategory':
        variationStyle = 'pt-[30px] border-0 relative';
        break;
      case 'article':
        variationStyle = 'pt-0 border-0 static';
        break;
    }

    if (type === 'big') {
      variationStyle = variationStyle + ' pb-0';
    } else {
      const pb = variation === 'subcategory' ? ' pb-[50px]' : ' pb-[30px]';
      variationStyle = variationStyle + pb;
    }

    return (
      <div
        ref={ref}
        className={clsx('card-content-wrapper w-full px-[19px] rounded-b-2xl', variationStyle)}
        style={{
          backgroundColor:
            variation === 'white'
              ? `var(--color-emphasize, #fff)`
              : variation === 'articleImage'
                ? `var(--color-secondary, ${backgroundHsl})`
                : variation === 'article'
                  ? 'var(--color-important, #fff)'
                  : backgroundHsl,
        }}
      >
        {children}
      </div>
    );
  },
);

export default ContentWrapper;
